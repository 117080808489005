// This file was automatically generated from widget.shiftcover.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Widget == 'undefined') { Nightloop.Templates.Widget = {}; }


Nightloop.Templates.Widget.ShiftCover = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="controls"><div id="coverflow-content-wrapper"></div><form class="styled" action="" style="', (opt_data.venue.is_nightlife_class || ! opt_data.content.shifts || opt_data.content.shifts.length < 2) ? 'display:none' : '', '"><div class="form-element select"><label for="shift-select"><p class="input"><select id="shift-select" name="shift-select">', (opt_data.content.shifts.length) ? '<option disabled="disabled">SHIFTS</option>' : '');
  var shiftList107 = opt_data.content.shifts;
  var shiftListLen107 = shiftList107.length;
  for (var shiftIndex107 = 0; shiftIndex107 < shiftListLen107; shiftIndex107++) {
    var shiftData107 = shiftList107[shiftIndex107];
    output.append('<option data-category="', soy.$$escapeHtml(shiftData107.category), '" value="', soy.$$escapeHtml(shiftData107.persistent_id), '">', soy.$$escapeHtml(shiftData107.name), '</option>');
  }
  output.append((opt_data.content.shift_reporting_periods.length) ? '<option disabled="disabled">SHIFTS REPORTING PERIOD</option>' : '');
  var shift_reporting_periodList119 = opt_data.content.shift_reporting_periods;
  var shift_reporting_periodListLen119 = shift_reporting_periodList119.length;
  for (var shift_reporting_periodIndex119 = 0; shift_reporting_periodIndex119 < shift_reporting_periodListLen119; shift_reporting_periodIndex119++) {
    var shift_reporting_periodData119 = shift_reporting_periodList119[shift_reporting_periodIndex119];
    output.append('<option kind="shift-reporting-id" data-category="', soy.$$escapeHtml(shift_reporting_periodData119.period_type), '" value="', soy.$$escapeHtml(shift_reporting_periodData119.id), '-', soy.$$escapeHtml(shift_reporting_periodData119.period_type), '">', soy.$$escapeHtml(shift_reporting_periodData119.shift_name), ' - ', soy.$$escapeHtml(shift_reporting_periodData119.period_type), ' (', soy.$$escapeHtml(shift_reporting_periodData119.start_time), ' - ', soy.$$escapeHtml(shift_reporting_periodData119.end_time), ')</option>');
  }
  output.append('</select><span class="downer"></span></p></label></div></form>', (! opt_data.no_cover) ? '<p class="button cover-flow"><!--- TODO:Sepaate out the arguments into a deferred object --><a id="show-cover-flow" data="' + soy.$$escapeHtml(opt_data.content._dateUrlParam) + '" onClick="modules.coverflow.helpers.displayCoverFlow( \'' + soy.$$escapeHtml(opt_data.venue.manager_base_url) + '\', ReservationSlideOut._shifts, \'' + soy.$$escapeHtml(opt_data.content.currentday_urlparam) + '\', \'' + soy.$$escapeHtml(opt_data.MEDIA_URL) + '\', \'' + soy.$$escapeHtml(opt_data.page) + '\')")><img src="' + soy.$$escapeHtml(opt_data.MEDIA_URL) + 'images/icons/coverflow-icon.png" /> Cover Flow</a></p>' : '', '</div><script type="text/javascript" src="', soy.$$escapeHtml(opt_data.settings.CACHE_MAP['js/manager.coverflow.js']), '"><\/script>');
  return opt_sb ? '' : output.toString();
};
