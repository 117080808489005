// This file was automatically generated from widget.dropdown.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Widget == 'undefined') { Nightloop.Templates.Widget = {}; }


Nightloop.Templates.Widget.DropDown = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div ', (opt_data.id) ? 'id="' + soy.$$escapeHtml(opt_data.id) + '" ' : '', 'class="float-left widget-dropdown-container  ', (opt_data.disabled && opt_data.disabled != 'false') ? 'disabled' : '', '  ', (opt_data.classes) ? soy.$$escapeHtml(opt_data.classes) : '', '"><a class=\'widget-dropdown-selected-link\'>', (opt_data.selected) ? soy.$$escapeHtml(opt_data.selected) : '-', ' </a><input type="hidden" class=\'widget-dropdown-selected-value\' name=\'', soy.$$escapeHtml(opt_data.name), '\'/><div class=\'widget-dropdown\'>');
  var optionList14855 = opt_data.options;
  var optionListLen14855 = optionList14855.length;
  for (var optionIndex14855 = 0; optionIndex14855 < optionListLen14855; optionIndex14855++) {
    var optionData14855 = optionList14855[optionIndex14855];
    output.append('<div class=\'widget-dropdown-option\'><div class=\'widget-dropdown-option-text\'>', soy.$$escapeHtml(optionData14855[0]), '</div><div class=\'widget-dropdown-option-value no-display\'>', soy.$$escapeHtml(optionData14855[1]), '</div></div>');
  }
  output.append('</div></div>');
  return opt_sb ? '' : output.toString();
};
