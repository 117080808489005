// This file was automatically generated from widget.followers.soy.
// Please don't edit this file by hand.

if (typeof Nightloop == 'undefined') { var Nightloop = {}; }
if (typeof Nightloop.Templates == 'undefined') { Nightloop.Templates = {}; }
if (typeof Nightloop.Templates.Widget == 'undefined') { Nightloop.Templates.Widget = {}; }


Nightloop.Templates.Widget.Followers = function(opt_data, opt_sb) {
  var output = opt_sb || new soy.StringBuilder();
  output.append('<div class="followers-component">', (opt_data.title) ? '<div class=\'title\'>Followers</div>' : '', '<span class="frozen-followers-area">');
  var followerList14870 = opt_data.frozenFollowers;
  var followerListLen14870 = followerList14870.length;
  for (var followerIndex14870 = 0; followerIndex14870 < followerListLen14870; followerIndex14870++) {
    var followerData14870 = followerList14870[followerIndex14870];
    output.append('<span class="pic">', soy.$$escapeHtml(followerData14870.initials), '<em class=\'name\'>', soy.$$escapeHtml(followerData14870.full_name), '</em></span>');
  }
  output.append('</span><span class="followers-area">');
  var followerList14878 = opt_data.followers;
  var followerListLen14878 = followerList14878.length;
  for (var followerIndex14878 = 0; followerIndex14878 < followerListLen14878; followerIndex14878++) {
    var followerData14878 = followerList14878[followerIndex14878];
    output.append('<span class="pic">', soy.$$escapeHtml(followerData14878.initials), '<span class="del follower-remove" fid="', soy.$$escapeHtml(followerData14878.id), '">x</span>', (opt_data.includeInput) ? '<input type="hidden" name="fid" value="' + soy.$$escapeHtml(followerData14878.id) + '" />' : '', '<em class=\'name\'>', soy.$$escapeHtml(followerData14878.full_name), '</em></span>');
  }
  output.append('</span><span id="insert-followers" class="add"><img src="', soy.$$escapeHtml(opt_data.MEDIA_URL), 'images/add_2x.png" /><select id="followers-select"></select></span></div>');
  return opt_sb ? '' : output.toString();
};
